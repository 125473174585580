import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountUpAlt, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';

function SortButton(props) {
  return (
    <button onClick={props.onClick} className="px-1 rounded-full py-1 md:my-2 hover:bg-gray-200">
      <FontAwesomeIcon
        icon={props.currentSort === 'asc' ? faSortAmountUpAlt : faSortAmountDown}
        color={'#B7BBC6'}
      />
    </button>
  );
}
export default SortButton;
