import { makeObservable, observable, action } from 'mobx';
import { makePersistable, clearPersistedStore } from 'mobx-persist-store';
import { client } from '../store';
import api from '../store/api';
import qs from 'qs';
import array from 'utils/helpers/array';
import LocalStorage from 'utils/helpers/localStorage';
import { alertHandler } from 'utils/middlewares/alertHandler';
import { toJS } from 'mobx';
import axios from 'axios';
import dateTime from 'utils/helpers/dateTime';

class StaffManagementStore {
  employees = {};

  constructor() {
    makeObservable(this, {
      employees: observable,
    });
  }

  setErrorAlert(visible, errorMessage, action = '') {
    this.errorMessage = errorMessage;
    this.errorAlert = visible;
    this.actionToPerform = action;
  }

  async getStaffEmployees(params, perPage = 8, pageNumber = 1) {
    let _params = params;
    _params.per_page = perPage;
    _params.page = pageNumber;

    let paramsString = qs.stringify(_params);
    return client()
      .get(`${api.staff()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.employees = data?.data;
          return data;
        }),
        action('fetchError', error => {
          // alertHandler(error.response);
          return error;
        })
      );
  }

  async addEmployee(params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.staff()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          this.getStaffEmployees(params);
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateEmployee(id, params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .patch(`${api.staff()}/${id}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async deleteEmployee(id, params, page) {
    let paramsString = qs.stringify(params);
    return client()
      .delete(`${api.staff()}/${id}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.getStaffEmployees(params, 8, page);
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }
}

export default StaffManagementStore;
