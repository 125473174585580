import {
  HALAL,
  KOSHER,
  GLUTENFREE,
  VEGETARIAN,
  VEGAN,
  DAIRYFREE,
  EXCLUSIVE,
  SPICY,
  NUTFREE,
  CONTAINSNUTS,
  BIODEGRADABLEPACKAGING,
  REUSABLEPACKAGING,
} from 'assets/img';
import { alertHandler } from 'utils/middlewares/alertHandler';

const menuItemBadge = badge => {
  switch (
    typeof badge === 'string' ? badge.replace(/\s+/g, '') : badge?.name?.replace(/\s+/g, '')
  ) {
    case 'HALAL':
      return HALAL;
    case 'KOSHER':
      return KOSHER;
    case 'GLUTENFREE':
      return GLUTENFREE;
    case 'VEGETARIAN':
      return VEGETARIAN;
    case 'VEGAN':
      return VEGAN;
    case 'DAIRYFREE':
      return DAIRYFREE;
    case 'EXCLUSIVE':
      return EXCLUSIVE;
    case 'SPICY':
      return SPICY;
    case 'NUTFREE':
      return NUTFREE;
    case 'CONTAINSNUTS':
      return CONTAINSNUTS;
    case 'BIODEGRADABLEPACKAGING':
      return BIODEGRADABLEPACKAGING;
    case 'REUSABLEPACKAGING':
      return REUSABLEPACKAGING;
    default:
      return HALAL;
  }
};

const checkCardDimensions = img => {
  if (img.width < 350 || img.height < 190) {
    alertHandler({
      title: 'Warning! Invalid image dimensions.',
      body: 'Please select the perfect image as per defined dimensions to get the best results.',
    });
  }
  return img.width < 350 || img.height < 190 ? false : true;
};

const checkBannerDimensions = img => {
  if (img.width < 1500 || img.height < 500) {
    alertHandler({
      title: 'Warning! Invalid image dimensions.',
      body: 'Please select the perfect image as per defined dimensions to get the best results.',
    });
  }
  return img.width < 1500 || img.height < 500 ? false : true;
};

export default {
  menuItemBadge,
  checkCardDimensions,
  checkBannerDimensions,
};
