import { useState } from 'react';
import { Switch } from '@headlessui/react';

function ToggleButton({ toggleState, setToggleState, disabled }) {
  return (
    <Switch
      disabled={disabled}
      checked={toggleState}
      onChange={setToggleState}
      className={`${
        toggleState ? 'bg-light-purple' : 'bg-gray-500'
      } relative inline-flex items-center h-6 rounded-full w-11 ${
        disabled && 'cursor-not-allowed opacity-50'
      }`}>
      <span
        className={`${
          toggleState ? 'translate-x-6' : 'translate-x-1'
        } inline-block w-4 h-4 transform bg-white rounded-full`}
      />
    </Switch>
  );
}
export default ToggleButton;
