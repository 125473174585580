import React, { useState, useContext } from 'react';
import { SideBar } from 'components/SideBar';
import { Header } from 'components/Header';
import Screen from 'utils/helpers/ScreenSize';
import { Loader } from 'components/Shared';
import { StoreContext } from 'index';
import { toJS } from 'mobx';

export default function Layout({ routes }) {
  const screenType = Screen.ScreenType();
  const [sidebar, toggleSidebar] = useState(screenType.isDesktop);
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);

  const { currentUser, isLoading } = userStore;
  let leftOpen = sidebar ? 'open' : 'closed';

  return (
    <>
      {isLoading && (
        <div className="modal-overlay absolute w-full z-50 h-full bg-transparent">
          <div
            className="relative opacity-100 w-full h-full bg-transparent"
            style={{ opacity: 0.8 }}>
            <Loader />
          </div>
        </div>
      )}

      {currentUser?.data && <Header toggleSidebar={toggleSidebar} sidebar={sidebar} />}

      <div id="layout">
        {currentUser?.data && (
          <div id="left" className={leftOpen}>
            <div className={`sidebar ${leftOpen}`}>
              <div className="content">
                <SideBar toggleSidebar={value => toggleSidebar(value)} />
              </div>
            </div>
          </div>
        )}

        <div id="main">
          {currentUser?.data ? (
            <div className="content m-2 border-2 px-2 rounded-lg">{routes}</div>
          ) : (
            routes
          )}
        </div>
      </div>
    </>
  );
}
