import React from 'react';

function OfficeUserOrderStatus({ status, sizeSmall = false }) {
  const getBackgroundColor = status => {
    switch (status) {
      case 'CANCELLED':
        return 'bg-status-cancelled';
      case 'completed':
        return 'bg-status-completed';
      default:
        return 'bg-status-sent';
    }
  };

  const getStatusColor = () => {
    switch (status) {
      case 'CANCELLED':
        return 'text-cancelled';
      case 'DELIVERED' || 'ORDER DELIVERED':
        return 'text-completed';
      default:
        return 'text-sent';
    }
  };

  const getStatusMapping = () => {
    switch (status) {
      case 'pending':
        return 'RECEIVED';
      case 'preview':
        return 'SENT TO RESTAURANT';
      case 'sent':
        return 'SENT TO RESTAURANT';
      case 'confirmed':
        return 'SENT TO RESTAURANT';
      case 'completed':
        return 'DELIVERED';
      case 'cancelled':
        return 'CANCELLED';
      default:
        return status;
    }
  };

  return (
    <>
      {sizeSmall ? (
        <div
          className={` ${getBackgroundColor(status)} ${
            status?.includes('ETA') ? 'bg-status-eta' : ''
          }  p-1 rounded-xl flex flex-row justify-center items-center`}>
          <p
            className={`text-xxxs font-inter-medium px-0 break-normal text-center ${getStatusColor()} ${
              status?.includes('ETA') ? 'text-eta' : ''
            } `}>
            {getStatusMapping()}
          </p>
        </div>
      ) : (
        <div
          className={` ${getBackgroundColor(status)} ${
            status?.includes('ETA') ? 'bg-status-eta' : ''
          }  p-2 rounded-xl flex flex-row items-center`}>
          <p
            className={`text-xs font-inter-medium px-2 ${getStatusColor()} ${
              status?.includes('ETA') ? 'text-eta' : ''
            } `}>
            {getStatusMapping()}
          </p>
        </div>
      )}
    </>
  );
}

export default OfficeUserOrderStatus;
