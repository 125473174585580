import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { shortHandText } from 'utils/helpers/string';
import { SortButton } from 'components/Shared';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { StoreContext } from 'index';
import { toJS } from 'mobx';

function Td({ children, title, to, className }) {
  const ContentTag = to ? Link : 'div';
  return (
    <td title={title} className={`py-3 whitespace-nowrap ${className}`}>
      <ContentTag to={to}>{children}</ContentTag>
    </td>
  );
}

export default function Cell({
  height,
  content,
  fixed,
  header,
  linkToPath = null,
  onSortClick,
  currentSort,
  shiftLeft = false,
  fixedTable = false,
}) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const { currentUser, isLoading } = userStore;
  const fixedClass = fixed ? ' Cell-fixed' : '';
  const headerClass = header ? ' Cell-header' : '';
  const style = height ? { height: `${height}px` } : undefined;

  const className = `Cell${fixedClass}${headerClass}`;

  const cellMarkup = header ? (
    // Add scope="col" to thead cells
    <th scope="col" className={'border-r px-2 align-top py-0 t-head'} style={style}>
      <div className="d-row justify-between items-start">
        <p className="t-row">{typeof content !== 'string' ? content.label : content}</p>
        {content?.sortBy && (
          <SortButton currentSort={currentSort} onClick={() => onSortClick(content?.sortBy)} />
        )}
      </div>
    </th>
  ) : fixed ? (
    isLoading ? (
      <Skeleton count={1} height="40px" />
    ) : (
      // Add scope="row" to the first cell of each tbody row
      <Td to={linkToPath} scope="row" className={`py-2 px-2 input-label`} style={style}>
        {content}
      </Td>
    )
  ) : (
    <Td
      to={linkToPath}
      title={typeof content !== 'string' ? '' : content}
      className={`py-2 px-2 ${typeof content !== 'string' && !shiftLeft && 'text-center'}`}>
      {typeof content === 'string' ? (
        isLoading ? (
          <Skeleton count={1} height="40px" />
        ) : (
          <small className="input-label ">{shortHandText(content)}</small>
        )
      ) : (
        <table className={`w-full ${fixedTable && 'table-fixed'}`}>
          {isLoading ? <Skeleton count={1} height="40px" /> : content}
        </table>
      )}
    </Td>
  );

  return cellMarkup;
}
