import { makeObservable, observable, action } from 'mobx';
import { makePersistable, clearPersistedStore } from 'mobx-persist-store';
import { client } from '../store';
import api from '../store/api';
import qs from 'qs';
import array from 'utils/helpers/array';
import LocalStorage from 'utils/helpers/localStorage';
import { alertHandler } from 'utils/middlewares/alertHandler';
import { toJS } from 'mobx';
import axios from 'axios';
import dateTime from 'utils/helpers/dateTime';

class marketManagementStore {
  markets = [];
  zones = [];

  constructor() {
    makeObservable(this, {
      markets: observable,
    });
  }

  async getMarkets(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.markets()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.markets = data?.data;
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async createMarket(params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .post(`${api.markets()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.getMarkets(params);
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateMarket(id, params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .put(`${api.markets()}/${id}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.getMarkets(params);
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  //Zones

  async getZones(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.zones()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.zones = data?.data;
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async createZone(params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .post(`${api.zones()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateZone(id, params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .put(`${api.zones()}/${id}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }
}

export default marketManagementStore;
