import React, { useEffect, useReducer, useContext } from 'react';
import { StoreContext } from 'index';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { toJS } from 'mobx';
import Input, { isValidPhoneNumber } from 'react-phone-number-input/input';
import { alertHandler } from 'utils/middlewares/alertHandler';
import { Tooltip, ToggleButton } from 'components/Shared';
import { restaurantEmployeeRoles } from 'utils/constants/employee';
import useEmployeeId from 'utils/helpers/user';

const CheckBox = ({ label, value, onChange, name, id, disabled = false }) => (
  <label className="flex items-center mt-2 ">
    <input
      checked={value}
      onChange={onChange}
      disabled={disabled}
      name={name}
      type="checkbox"
      id={id}
      className="form-checkbox cursor-pointer"
    />
    <span className={`ml-2 text-xs cursor-pointer ${disabled && 'opacity-50'}`}>{label}</span>
  </label>
);

function RestaurantUser({ selected, closeModal, userDetails, removeEmployee, showNotifications }) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const { employeeId } = new useEmployeeId();
  const history = useHistory();
  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    role: '',

    orderReminderEmail: true,
    orderReminderPortalAlert: true,
    scheduleUpdatesEmail: true,
    scheduleUpdatesPortalAlert: true,
    orderUpdatesEmail: true,
    orderUpdatesPortalAlert: true,
    orderConfirmationEmail: true,
    orderConfirmationPortalAlert: true,
    orderConfirmationPhoneCall: false,
    muteAll: true,
  };
  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return action.payload;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }
  const [provider, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: 'reset', payload: initialState });
  }, [selected]);

  const submit = e => {
    e.preventDefault();

    if (provider.orderConfirmationPhoneCall && !provider.phone) {
      alertHandler({
        title: 'Please add a phone number before enabling this notification.',
      });
      return;
    } else if (provider.phone && !isValidPhoneNumber(provider.phone)) {
      alertHandler({
        title: 'You have entered an invalid phone number.',
        body: 'At this time only numbers with country code +1 are accepted. And in the following format: (123) 234-5678',
      });
    } else if (!provider.role)
      alertHandler({
        title: 'Please select a role.',
        body: '',
      });
    else {
      store.userStore.setLoader(true);
      let payload = {
        email: provider.email,
        first_name: provider.firstName,
        last_name: provider.lastName,
        role: provider.role.value,
        phone: provider.phone,
        restaurant_id: selected?.value,
        restaurant_employee: {
          order_confirmation_phone_call: provider.orderConfirmationPhoneCall,
        },
      };

      store.userManagementStore
        .addRestaurantEmployee({ employee_id: employeeId }, payload)
        .then(response => {
          store.userStore.setLoader(false);
          if (response.employee?.id) {
            closeModal();
            store.userStore.setLoader(false);
            history.push(
              `/user-detail/${response?.employee?.user_id}?user_type=${
                response?.employee?.organization_type
              }&tab=${response?.employee?.organization_type === 'Location' ? 0 : 2}`
            );
          }
        });
    }
  };

  const fetchAndFillUser = email => {
    dispatch({ field: 'userPrefilled', value: false });
    store.userStore.setLoader(true);
    store.userManagementStore.fetchUser(email, { employee_id: employeeId }).then(response => {
      store.userStore.setLoader(false);
      if (response?.status === 200) {
        dispatch({ field: 'userPrefilled', value: true });
        dispatch({ field: 'phone', value: response?.data?.data?.attributes?.phone });
        dispatch({ field: 'firstName', value: response?.data?.data?.attributes?.first_name });
        dispatch({ field: 'lastName', value: response?.data?.data?.attributes?.last_name });
      }
    });
  };

  const resetPrefilled = () => {
    if (provider.email) {
      dispatch({ field: 'userPrefilled', value: false });
      dispatch({ field: 'phone', value: '' });
      dispatch({ field: 'firstName', value: '' });
      dispatch({ field: 'lastName', value: '' });
    }
  };

  const onRoleChange = selected => {
    dispatch({ field: 'role', value: selected });
  };

  const onNotificationChange = e => {
    dispatch({ field: e.target.id, value: e.target.checked });
  };
  return (
    <div>
      <div className="flex flex-col">
        <form className="rounded pt-4 w-full" onSubmit={e => submit(e)}>
          <div className="d-row flex-wrap">
            <div className="w-full md:w-1/2 md:pr-1">
              <small className="input-label">First Name*</small>
              <input
                className="input-light"
                name="firstName"
                id="firstName"
                type="text"
                value={provider.firstName}
                placeholder="Enter Name"
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                required
              />
            </div>

            <div className="w-full md:w-1/2 md:pr-1">
              <small className="input-label">Last Name*</small>
              <input
                className="input-light"
                name="lastName"
                id="lastName"
                value={provider.lastName}
                placeholder="Enter Name"
                type="text"
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                required
              />
            </div>
          </div>
          <div className="d-row flex-wrap">
            <div className="w-full md:w-1/2 md:pr-1">
              <small className="input-label">Email*</small>
              <input
                className="input-light"
                placeholder="Enter Email"
                name="email"
                id="email"
                value={provider.email}
                onFocus={resetPrefilled}
                onBlur={e => {
                  fetchAndFillUser(e.target.value);
                }}
                type="email"
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                required
              />
            </div>

            <div className="w-full md:w-1/2">
              <small className="input-label">Phone Number</small>
              <Input
                defaultCountry="CA"
                value={provider.phone}
                className="input-light"
                placeholder="Example: (506) 234-5678"
                onChange={value => dispatch({ field: 'phone', value: value ? value : '' })}
              />
            </div>
          </div>

          <small className="input-label">Role*</small>
          <Select
            className="font-sf-regular capitalize text-xs focus:outline-none ring-transparent shadow-none"
            options={restaurantEmployeeRoles}
            value={provider.role}
            onChange={onRoleChange}
          />

          {showNotifications && (
            <>
              <div className="d-col pt-3">
                <div className="d-row w-full justify-between">
                  <div className="d-row">
                    <small className="font-inter-medium text-sm">Mute All Notification's</small>
                    <Tooltip
                      tooltipText={'Stop all notifications to your account.'}
                      id="mute-notification"
                    />
                  </div>
                  <ToggleButton disabled={true} />
                </div>
              </div>

              <div className="d-col pt-3">
                <div className="d-row">
                  <div className="d-col w-1/2">
                    <div className="d-row">
                      <small className="font-inter-medium text-xs">Order Reminder</small>
                      <Tooltip
                        tooltipText="Receive a notification 60 minutes prior to the order deadline. At this point orders cannot be removed or modified."
                        id="mute-notification"
                      />
                    </div>
                    <CheckBox
                      value={provider.orderReminderEmail}
                      name="order_reminder_email"
                      onChange={e => onNotificationChange(e)}
                      id="orderReminderEmail"
                      label="Email"
                      disabled
                    />
                    <CheckBox
                      value={provider.orderReminderPortalAlert}
                      name="order_reminder_portal_alert"
                      id="orderReminderPortalAlert"
                      onChange={e => onNotificationChange(e)}
                      label="Portal Alert"
                      disabled
                    />
                  </div>

                  <div className="d-col w-1/2">
                    <div className="d-row">
                      <small className="font-inter-medium text-xs">Schedule Updates</small>
                      <Tooltip
                        tooltipText="Receive a notification for your upcoming week's schedule along with any in week adjustments."
                        id="mute-notification"
                      />
                    </div>
                    <CheckBox
                      value={provider.scheduleUpdatesEmail}
                      name="schedule_updates_email"
                      id="scheduleUpdatesEmail"
                      onChange={e => onNotificationChange(e)}
                      label="Email"
                      disabled
                    />
                    <CheckBox
                      value={provider.scheduleUpdatesPortalAlert}
                      name="schedule_updates_portal_alert"
                      id="scheduleUpdatesPortalAlert"
                      onChange={e => onNotificationChange(e)}
                      label="Portal Alert"
                      disabled
                    />
                  </div>
                </div>

                <div className="d-row mt-4">
                  <div className="d-col w-1/2">
                    <div className="d-row">
                      <small className="font-inter-medium text-xs">Order Updates</small>
                      <Tooltip
                        tooltipText="Receive a notification for all changes(late orders, order modifications, pickup time) to your order after confirmation."
                        id="mute-notification"
                      />
                    </div>
                    <CheckBox
                      value={provider.orderUpdatesEmail}
                      name="order_updates_email"
                      id="orderUpdatesEmail"
                      onChange={e => onNotificationChange(e)}
                      label="Email"
                      disabled
                    />
                    <CheckBox
                      value={provider.orderUpdatesPortalAlert}
                      name="order_updates_portal_alert"
                      id="orderUpdatesPortalAlert"
                      onChange={e => onNotificationChange(e)}
                      label="Portal Alert"
                      disabled
                    />
                  </div>

                  <div className="d-col w-1/2">
                    <div className="d-row">
                      <small className="font-inter-medium text-xs">Order Confirmation</small>
                      <Tooltip
                        tooltipText="Receive a notification at the order deadline. All order's must be confirmed at this point."
                        id="mute-notification"
                      />
                    </div>
                    <CheckBox
                      value={provider.orderConfirmationEmail}
                      name="order_confirmation_email"
                      id="orderConfirmationEmail"
                      onChange={e => onNotificationChange(e)}
                      label="Email"
                      disabled
                    />
                    <CheckBox
                      value={provider.orderConfirmationPortalAlert}
                      name="order_confirmation_portal_alert"
                      id="orderConfirmationPortalAlert"
                      onChange={e => onNotificationChange(e)}
                      label="Portal Alert"
                      disabled
                    />

                    <CheckBox
                      value={provider.orderConfirmationPhoneCall}
                      name="order_confirmation_phone_call"
                      id="orderConfirmationPhoneCall"
                      onChange={e => onNotificationChange(e)}
                      label="Phone call"
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="items-end flex flex-col pt-2 md:pt-5">
            {userDetails?.id && (
              <div className="w-full">
                <button
                  type="button"
                  className="btn-purple text-sm w-full bg-light-red break-normal"
                  onClick={() => {
                    removeEmployee({
                      id: userDetails.id,
                      fullName: userDetails.first_name + ' ' + userDetails.last_name,
                    });
                  }}>
                  Delete {userDetails?.first_name}
                </button>

                <button
                  className="btn-purple text-sm w-full my-2 bg-pink-purple break-normal"
                  type="button">
                  Reset Password
                </button>
              </div>
            )}

            <button className="btn-purple w-auto text-xs" type="submit">
              {userDetails?.id ? 'Update Details' : ' Add User'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RestaurantUser;
