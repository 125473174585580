import React, { useReducer, useContext, useEffect, useState, useRef, useMemo } from 'react';
import { StoreContext } from 'index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toJS } from 'mobx';
import {
  ModalBackground,
  ToggleButton,
  TimePicker,
  Dropdown,
  ErrorToast,
  Button,
} from 'components/Shared';
import useEmployeeId from 'utils/helpers/user';
import { useParams } from 'react-router-dom';
import { CustomDatePicker } from 'components/DatePicker';
import { faPlus, faTrashAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import object from 'utils/helpers/object';
import dateTime from 'utils/helpers/dateTime';
import moment from 'moment';
import array from 'utils/helpers/array';
import Screen from 'utils/helpers/ScreenSize';

function HoursOfOperations({
  visible,
  closeModal,
  operatingHours,
  specialHours,
  type,
  associatedMenus = [],
}) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const { employeeId } = new useEmployeeId();
  const { id } = useParams();
  const [weekDays, setWeekDays] = useState([]);
  const [specialDays, setSpecialDays] = useState([]);
  const screenType = Screen.ScreenType();
  const restaurantManagementStore = toJS(store.restaurantManagementStore);
  const { defaultMenuType, restaurantDetail } = restaurantManagementStore;
  const timeZone = restaurantDetail?.data?.attributes?.tzinfo;

  const initialState = {
    menus: [],
    menusError: false,
  };
  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return action.payload;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }
  const [provider, dispatch] = useReducer(reducer, initialState);

  useMemo(() => {
    let daysArrayUpdate = [
      { day: 'sunday', operational: false, hours: [] },
      { day: 'monday', operational: false, hours: [] },
      { day: 'tuesday', operational: false, hours: [] },
      { day: 'wednesday', operational: false, hours: [] },
      { day: 'thursday', operational: false, hours: [] },
      { day: 'friday', operational: false, hours: [] },
      { day: 'saturday', operational: false, hours: [] },
    ];
    operatingHours?.map(day => {
      let attributes = day.attributes;
      let dayFound = daysArrayUpdate.find(o => o.day === attributes.day);

      dayFound?.hours.push({
        start: attributes.from,
        end: attributes.to,
        id: day.id,
        active: attributes.active,
      });
      dayFound.operational = dayFound?.hours.filter(hour => !hour.active)?.length === 0;
      return dayFound;
    });

    setWeekDays(daysArrayUpdate);
  }, [operatingHours, visible]);

  useMemo(() => {
    let specialDaysUpdate = [];

    specialHours?.map(specialHour => {
      let day = specialHour.attributes;
      let dayFound = specialDaysUpdate.find(o =>
        moment(o.date).isSame(moment(day.date.split('T')[0]).toDate())
      );

      if (dayFound) {
        dayFound.hours.push({
          start: day?.from,
          end: day?.to,
          id: specialHour.id,
        });
        dayFound.date = moment(day.date.split('T')[0]).toDate();
      } else {
        specialDaysUpdate = [
          ...specialDaysUpdate,
          {
            date: moment(day.date?.split('T')[0]).toDate(),
            operational: false,
            hours: [
              {
                start: day?.from,
                end: day?.to,
                id: specialHour.id,
              },
            ],
          },
        ];
      }
    });

    specialDaysUpdate?.map(day =>
      day.hours.map(hour => {
        if (hour.start !== '00:00:00' && hour.end !== '00:00:00') day.operational = true;
      })
    );

    setSpecialDays(specialDaysUpdate);
  }, [specialHours, visible]);

  // handle click event of the Remove button
  const handleRemoveClick = (selected, index) => {
    let updated = weekDays?.map(day => {
      if (day.day === selected.day) {
        const list = [...day.hours];
        let hour = list[index];
        if (hour.id) hour._destroy = true;
        else list.splice(index, 1);
        day.hours = list;
      }
      return day;
    });
    setWeekDays(updated);
  };

  // handle click event of the Add button
  const handleAddClick = selected => {
    let updated = weekDays?.map(day => {
      if (day.day === selected.day) {
        day.hours = [...day.hours, { start: '07:00:00', end: '19:00:00' }];
      }
      return day;
    });
    setWeekDays(updated);
  };

  const setToggleState = (checked, selected) => {
    store.restaurantManagementStore.updateRestaurantAvailability(
      type === 'restaurant' ? id : defaultMenuType?.value,
      {
        employee_id: employeeId,
        type: type === 'restaurant' ? 'Restaurant' : 'Menu',
        day: selected.day,
        active: checked,
      }
    );

    let updated = weekDays?.map(day => {
      if (day.day === selected.day) {
        day.operational = checked;
        if (checked && array.isEmpty(day.hours))
          day.hours = [{ start: '07:00', end: '19:00', active: true }];
      }
      return day;
    });
    setWeekDays(updated);
  };

  const handleTimeChange = (time, selected, index, picker) => {
    let updated = weekDays?.map(day => {
      if (day.day === selected.day) {
        let list = [...day.hours];
        if (picker === 'start') list[index].start = time;
        else list[index].end = time;
        day.hours = list;
      }
      return day;
    });
    setWeekDays(updated);
  };

  //for special days

  const handleAddSpecialDays = () => {
    let special = [
      ...specialDays,
      {
        date: new Date(),
        operational: false,
        hours: [{ start: '00:00:00', end: '00:00:00' }],
        // hours: [],
      },
    ];
    setSpecialDays(special);
  };

  const handleRemoveSpecialDay = (day, dayIndex) => {
    let updated = specialDays?.map((day, ind) => {
      if (ind === dayIndex) {
        day.removed = true;
        const list = [...day.hours];
        day.hours = list?.map(hour => {
          if (hour.id) return { ...hour, _destroy: true };
          return hour;
        });
      }
      return day;
    });
    setSpecialDays(updated);
  };

  const setSpecialDayAvailability = (checked, selected, dayIndex) => {
    let updated = specialDays?.map((day, ind) => {
      if (ind === dayIndex) {
        day.operational = checked;

        if (!checked) {
          const list = [...day.hours];

          day.hours = list
            ?.map(hour => {
              if (hour.id && hour.start === '00:00:00' && hour.end === '00:00:00')
                return { ...hour, _destroy: false };
              return { ...hour, _destroy: true };
            })
            .filter(hour => hour.id);

          if (
            array.isEmpty(
              day.hours.filter(hour => hour.start === '00:00:00' && hour.end === '00:00:00')
            )
          )
            day.hours = [...day.hours, { start: '00:00:00', end: '00:00:00' }];
        } else {
          const list = [...day.hours];
          day.hours = list
            ?.map(hour => {
              if (hour.id && hour.start === '00:00:00' && hour.end === '00:00:00')
                return { ...hour, _destroy: true };
              return { ...hour, _destroy: false };
            })
            .filter(hour => {
              return hour.id && hour.start !== '00:00' && hour.end !== '00:00';
            });

          if (
            array.isEmpty(
              day.hours.filter(hour => hour.start !== '00:00:00' && hour.end !== '00:00:00')
            )
          )
            day.hours = [...day.hours, { start: '07:00', end: '19:00', active: true }];
        }
      }
      return day;
    });
    setSpecialDays(updated);
  };

  const setSpecialDayDate = (date, index) => {
    if (
      specialDays?.filter(
        specialDay =>
          dateTime.formatDateWithtimzone(specialDay?.date, 'YYYY-MM-DD', timeZone) ===
          dateTime.formatDateWithtimzone(date, 'YYYY-MM-DD', timeZone)
      )?.length === 0
    ) {
      let updated = specialDays?.map((day, ind) => {
        if (ind === index) {
          day.date = date;
        }
        return day;
      });
      setSpecialDays(updated);
    }
  };

  const handleAddSpecialHour = (day, index) => {
    let updated = specialDays?.map((day, ind) => {
      if (ind === index) {
        day.hours = [...day.hours, { start: '07:00:00', end: '19:00:00' }];
      }
      return day;
    });
    setSpecialDays(updated);
  };

  const handleRemoveSpecialHour = (selected, hourIndex, dayIndex) => {
    let updated = specialDays?.map((day, ind) => {
      if (ind === dayIndex) {
        const list = [...day.hours];
        let hour = list[hourIndex];
        if (hour.id) hour._destroy = true;
        else list.splice(hourIndex, 1);
        day.hours = list;
      }
      return day;
    });
    setSpecialDays(updated);
  };

  const handleSpecialDayTime = (time, selected, hourIndex, picker, dayIndex) => {
    let updated = specialDays?.map((day, ind) => {
      if (ind === dayIndex) {
        let list = day?.hours?.filter(hour => hour.start !== '00:00:00' && hour.end !== '00:00:00');
        if (picker === 'start') list[hourIndex].start = time;
        else list[hourIndex].end = time;
        day.hours = list;
      }
      return day;
    });
    setSpecialDays(updated);
  };

  const saveMenu = () => {
    let payload = {
      restaurant_id: id,
      operating_hours_attributes: [],
      special_hours_attributes: [],
    };

    weekDays?.map((day, index) => {
      day?.hours?.map(hour => {
        let obj = {
          day: day?.day,
          from: hour.start,
          to: hour.end,
        };

        if (hour?.id) obj.id = hour.id;

        if (hour?._destroy) obj._destroy = hour?._destroy;

        payload.operating_hours_attributes = [...payload?.operating_hours_attributes, obj];
      });
    });

    specialDays
      ?.filter(day => day?.date !== '')
      ?.map((day, index) => {
        day?.hours?.map(hour => {
          if (hour.start !== '' && hour.end !== '') {
            let obj = {
              date: moment(day.date).format('YYYY-MM-DD'),
              from: hour.start,
              to: hour.end,
            };

            if (hour?.id) obj.id = hour.id;
            if (hour?._destroy) obj._destroy = hour?._destroy;

            payload.special_hours_attributes = [...payload?.special_hours_attributes, obj];
          }
        });
      });

    store.userStore.setLoader(true);
    store.restaurantManagementStore
      .createMenu('update', payload, employeeId, id, defaultMenuType?.value)
      .then(response => {
        store.userStore.setLoader(false);
        if (response?.data?.id) closeModal();
      });
  };

  const saveRestaurant = () => {
    if (array.isPresent(associatedMenus) && array.isEmpty(provider.menus)) {
      dispatch({ field: 'menusError', value: true });
      return;
    }

    let payload = { menu_ids: provider?.menus?.map(menu => menu?.value) };

    payload.profile = {
      operating_hours_attributes: weekDays
        ?.map(day => {
          return day?.hours?.map(hour => {
            let slot = {
              day: day.day,
              from: hour.start,
              to: hour.end,
            };
            if (hour?.id) slot.id = hour.id;
            if (hour?._destroy) slot._destroy = hour?._destroy;
            return slot;
          });
        })
        .flat(),

      special_hours_attributes: specialDays
        ?.filter(day => day.date)
        ?.map(day => {
          return day?.hours?.map(hour => {
            let slot = {
              date: moment(day.date).format('YYYY-MM-DD'),
              from: hour.start,
              to: hour.end,
            };
            if (hour?.id) slot.id = hour.id;
            if (hour?._destroy) slot._destroy = hour?._destroy;
            return slot;
          });
        })
        .flat(),
    };

    store.userStore.setLoader(true);
    store.restaurantManagementStore
      .updateRestaurantProfile(id, object.removeExtraKeys(payload), { employee_id: employeeId })
      .then(response => {
        store.restaurantManagementStore
          .getRestaurantDetail(id, { employee_id: employeeId })
          .then(response => {
            closeModal();
            store.userStore.setLoader(false);
          });
      });
  };

  return (
    <div
      className={
        visible
          ? `fixed pin inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center z-20`
          : 'hidden'
      }>
      <ModalBackground />

      <div
        className="rounded-lg bg-white overflow-scroll shadow-md transform transition-all sm:max-w-lg sm:w-full animate-fade-in-down"
        role="dialog"
        aria-modal="true"
        style={{
          minWidth: screenType.isDesktop ? '65%' : '100%',
          minHeight: '95%',
          maxHeight: '100%',
        }}
        aria-labelledby="modal-headline">
        <div className="flex p-6 flex-col" key={weekDays}>
          <button onClick={closeModal}>
            <FontAwesomeIcon
              icon={faTimes}
              color={'#ADB4C3'}
              size="lg"
              className="absolute top-4 right-4"
            />
          </button>
          <span class="text-sm font-inter-semibold text-dark-purple">Hours</span>
          {weekDays?.map(day => (
            <div className="flex flex-col md:flex-row items-start md:items-center mt-6 ml-0 md:ml-16">
              <div className="flex flex-row w-1/3">
                <small className="pr-2 w-auto md:w-1/2 input-label text-sm capitalize">
                  {day.day}
                </small>
                <div className="md:w-1/2">
                  <ToggleButton
                    toggleState={day.operational}
                    setToggleState={checked => {
                      setToggleState(checked, day);
                    }}
                  />
                </div>
                <small className="px-2 md:w-1/2 input-label text-sm">
                  {day.operational ? 'Open' : 'Closed'}
                </small>
              </div>

              <div className="d-col items-start justify-start content-start">
                {day.operational &&
                  day.hours.map((hour, i, arr) => {
                    return (
                      <div className="flex flex-row flex-wrap animate-fade-in-up items-center ml-0 md:ml-5 mb-1">
                        <div className="flex flex-row flex-wrap items-center justify-end">
                          <div className="mr-0 md:mr-2">
                            <small className="input-label">
                              {dateTime.checkAndGetTimezone(timeZone)}
                            </small>
                            <TimePicker
                              onChange={time => handleTimeChange(time, day, i, 'start')}
                              value={hour.start}
                              disabled={hour?._destroy}
                            />
                          </div>

                          {screenType?.isDesktop && <strong>-</strong>}

                          <div className="ml-2">
                            <small className="input-label">
                              {dateTime.checkAndGetTimezone(timeZone)}
                            </small>
                            <TimePicker
                              onChange={time => handleTimeChange(time, day, i, 'end')}
                              value={hour.end}
                              disabled={hour?._destroy}
                            />
                          </div>
                        </div>

                        <div className="d-row justify-end w-full md:w-auto">
                          {arr.length - 1 === i && arr.length < 5 && (
                            <button
                              type="button"
                              onClick={() => handleAddClick(day)}
                              className="ml-2 hover:bg-blue-100 p-1 rounded-md">
                              <FontAwesomeIcon icon={faPlus} color={'#6D69D3'} size="md" />
                            </button>
                          )}

                          {!hour._destroy && (
                            <button
                              type="button"
                              className="ml-2 hover:bg-red-100 p-1 rounded-md"
                              onClick={() => handleRemoveClick(day, i)}>
                              <FontAwesomeIcon icon={faTrashAlt} color={'#960A0A'} size="md" />
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>

              <>
                {day.operational && day.hours.length === 0 && (
                  <button
                    type="button"
                    onClick={() => handleAddClick(day)}
                    className=" text-light-purple input-label font-inter-medium pl-8">
                    Add hours
                  </button>
                )}
              </>
            </div>
          ))}
          <div className="border w-full my-7" />
          <div>
            <div className="d-row justify-between">
              <span class="text-sm font-inter-semibold text-dark-purple">Special Hours</span>

              <button onClick={handleAddSpecialDays} className="btn-purple ml-2 text-xs md:w-1/6">
                Create Event
              </button>
            </div>

            {specialDays?.map((day, index) => (
              <div
                className={`flex flex-col md:flex-row mt-2 ml-0 md:ml-16 ${
                  day.removed && 'opacity-50'
                }`}>
                <div className="d-row w-full">
                  <div className="mr-2 d-col justify-center items-center">
                    <CustomDatePicker
                      setDate={date => {
                        setSpecialDayDate(date, index);
                      }}
                      selectedDate={day.date}
                      transparent
                      placeholder="Select Date"
                      minDate={new Date()}
                    />
                  </div>
                  <div className="md:w-1/12">
                    <ToggleButton
                      toggleState={day.operational}
                      setToggleState={checked => {
                        setSpecialDayAvailability(checked, day, index);
                      }}
                    />
                  </div>
                  <small className="px-2 md:w-1/12 input-label text-sm">
                    {day.operational ? 'Open' : 'Close'}
                  </small>

                  <button
                    type="button"
                    className="hover:bg-red-100 rounded-md px-1"
                    onClick={() => handleRemoveSpecialDay(day, index)}>
                    <FontAwesomeIcon icon={faTimes} color={'#960A0A'} size="1x" />
                  </button>
                </div>

                {day.operational && (
                  <div className="d-col">
                    {day.hours
                      ?.filter(hour => hour.start !== '00:00:00' && hour.end !== '00:00:00')
                      ?.map((hour, i, arr) => {
                        return (
                          <div className="d-row animate-fade-in-up items-end ml-0 md:ml-5 mt-1">
                            <div
                              className={`flex flex-row ${
                                screenType?.isTabletOrMobile && 'flex-wrap items-center justify-end'
                              }`}>
                              <div className="d-col">
                                <small className="input-label">
                                  {dateTime.checkAndGetTimezone(timeZone)}
                                </small>
                                <TimePicker
                                  onChange={time =>
                                    handleSpecialDayTime(time, day, i, 'start', index)
                                  }
                                  value={hour.start}
                                  disabled={hour?._destroy}
                                />
                              </div>

                              {screenType.isDesktop && <strong className="mx-2">-</strong>}

                              <div className="d-col">
                                <small className="input-label">
                                  {dateTime.checkAndGetTimezone(timeZone)}
                                </small>
                                <TimePicker
                                  onChange={time =>
                                    handleSpecialDayTime(time, day, i, 'end', index)
                                  }
                                  value={hour.end}
                                  disabled={hour?._destroy}
                                />
                              </div>
                            </div>

                            <div className="d-row">
                              {arr.length - 1 === i && arr.length < 5 && (
                                <button
                                  type="button"
                                  onClick={() => handleAddSpecialHour(day, index)}
                                  className="ml-2 hover:bg-blue-100 p-1 rounded-md">
                                  <FontAwesomeIcon icon={faPlus} color={'#6D69D3'} size="md" />
                                </button>
                              )}

                              {arr.length > 1 && (
                                <button
                                  type="button"
                                  className="ml-2 hover:bg-red-100 p-1 rounded-md"
                                  onClick={() => handleRemoveSpecialHour(day, i, index)}>
                                  <FontAwesomeIcon icon={faTrashAlt} color={'#960A0A'} size="md" />
                                </button>
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            ))}
          </div>

          {type === 'restaurant' && !array.isEmpty(associatedMenus) && (
            <>
              <div className="border w-full my-7" />

              <div className="d-col">
                <span class="text-sm font-inter-semibold text-dark-purple mb-3">
                  Apply to menus*
                </span>
                <Dropdown
                  label="Select"
                  options={associatedMenus}
                  isMulti
                  selected={provider.menus}
                  onChange={values => {
                    dispatch({ field: 'menusError', value: false });
                    dispatch({ field: 'menus', value: values });
                  }}
                />
              </div>

              <ErrorToast
                visible={provider?.menusError}
                error="Please select the menus to apply."
                transparent
              />
            </>
          )}
          <div className="d-row justify-end mt-7">
            <button className="btn-outline text-xs w-auto md:w-1/6 mr-1" onClick={closeModal}>
              Close
            </button>
            <Button
              className="btn-purple text-xs w-auto md:w-1/6"
              onClick={type === 'restaurant' ? saveRestaurant : saveMenu}
              label="Save"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HoursOfOperations;
