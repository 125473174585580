import { makeObservable, observable, action } from 'mobx';
import { makePersistable, clearPersistedStore } from 'mobx-persist-store';
import { client } from '../store';
import api from '../store/api';
import array from 'utils/helpers/array';
import qs from 'qs';
import LocalStorage from 'utils/helpers/localStorage';
import { toJS } from 'mobx';
import { alertHandler } from 'utils/middlewares/alertHandler';

class UserStore {
  currentUser = {};
  isLoading = false;
  actionToPerform = '';
  //To display a central error popups
  errorMessage = '';
  errorAlert = false;
  defaultRegion = {};

  constructor() {
    makePersistable(this, {
      name: 'UserStore',
      properties: ['currentUser', 'defaultRegion'],
      storage: window.localStorage,
    });
    makeObservable(this, {
      isLoading: observable,
      currentUser: observable,
      errorMessage: observable,
      errorAlert: observable,
      defaultRegion: observable,
      setLoader: action,
      clearStoredData: action,
      actionToPerform: observable,
    });
  }

  setErrorAlert(visible, errorMessage, action = '') {
    this.errorMessage = errorMessage;
    this.errorAlert = visible;
    this.actionToPerform = action;
  }

  async clearStoredData() {
    this.currentUser = {};
    this.defaultRegion = {};
    document.cookie = 'username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    await clearPersistedStore(this);
  }

  async setLoader(value) {
    this.isLoading = value;
  }

  async signIn(payload) {
    return client()
      .post(api.login(), payload)
      .then(
        action('fetchSuccess', data => {
          const regionEmployees = array.getRegionOrganisations(data?.data);
          if (array.isPresent(regionEmployees)) {
            this.currentUser = data?.data;
            const region = regionEmployees[0];
            this.setDefaultRegion({
              label: region?.attributes?.organization_name,
              value: region?.id, //id = employee id
              role: region?.attributes?.role,
            });
            LocalStorage.setAccessToken(data?.data?.data?.attributes?.access_token);
          }
          return data.data;
        }),
        action('fetchError', error => {
          return error.response;
        })
      );
  }

  async forgotPassword(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.forgotPassword()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler({
            title: 'No email found',
            body: 'No user associated with this account.',
          });
          return error;
        })
      );
  }

  async resetPassword(payload) {
    return client()
      .post(api.resetPassword(), payload)
      .then(
        action('fetchSuccess', data => {
          alertHandler(
            {
              title: 'Password Reset Successful',
              body: 'Your password has been reset successfully.',
            },
            'navigateLogin'
          );
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(
            {
              title: 'Unable to update',
              body: 'Link is invalid or expired. Try generating a new link. Please login now to access your account.',
            },
            'navigateLogin'
          );
          return error;
        })
      );
  }

  async getStaffUserProfile() {
    let currentUser = toJS(this.currentUser);
    let defaultRegion = toJS(this.defaultRegion);

    return client()
      .get(`/profiles/${currentUser?.data?.attributes?.email}?employee_id=${defaultRegion?.value}`)
      .then(
        action('fetchSuccess', data => {
          const regionEmployees = array.getRegionOrganisations(data?.data);
          if (array.isPresent(regionEmployees)) {
            this.currentUser = data?.data;
            const region = regionEmployees[0];
            this.setDefaultRegion({
              label: region?.attributes?.organization_name,
              value: region?.id, //id = employee id
              role: region?.attributes?.role,
            });
          }
          return data?.data?.data;
        }),
        action('fetchError', error => {
          alertHandler({
            title: 'No Information Found',
            body: 'No user associated with this account.',
          });
          return error;
        })
      );
  }

  async updateUserProfile(payload) {
    let currentUser = toJS(this.currentUser);
    let defaultRegion = toJS(this.defaultRegion);

    return client()
      .put(api.profile(currentUser?.data?.id, defaultRegion?.value), payload)
      .then(
        action('fetchSuccess', data => {
          this.restaurantUser = data?.data?.data;
          this.currentUser = data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler({
            title: 'Invalid Password',
            body: 'The current password entered is incorrect.',
          });
          return error;
        })
      );
  }

  async updateStaffUserProfile(payload) {
    let currentUser = toJS(this.currentUser);
    let defaultRegion = toJS(this.defaultRegion);

    return client()
      .put(api.profile(currentUser?.data?.id, defaultRegion?.value), payload)
      .then(
        action('fetchSuccess', data => {
          this.currentUser = data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler({
            title: 'User not found',
            body: 'Please try again later.',
          });
          return error;
        })
      );
  }

  setDefaultRegion(region) {
    this.defaultRegion = region;
  }
}

export default UserStore;
