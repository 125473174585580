import Select from 'react-select';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Dropdown({
  label,
  options,
  selected,
  onChange,
  isMulti = false,
  isClearable = false,
  hideSelectedOptions = false,
  disabled = false,
  onFocus,
  lowerCase = false,
  style,
  menuPosition,
}) {
  return (
    <div className={`${disabled && 'cursor-not-allowed'} w-full`}>
      <Select
        placeholder={label}
        className={`input-label ${lowerCase ? 'lowercase' : 'capitalize'} overflow-visible`}
        value={selected}
        onChange={onChange}
        onFocus={onFocus}
        options={options}
        isMulti={isMulti}
        hideSelectedOptions={hideSelectedOptions}
        isDisabled={disabled}
        isClearable={isClearable}
        styles={style}
        menuPosition={menuPosition}
      />
    </div>
  );
}
