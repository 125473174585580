import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

function ShipmentStatus(props) {
  return (
    <div className="flex flex-row flex-wrap ml-0 md:ml-2 w-full self-end">
      <div className="d-row items-center">
        <small className="input-label">Published</small>
        <div className="h-3 w-3 bg-green-700 ml-1" />
      </div>
      <div className="d-row items-center px-3">
        <small className="input-label">Saved</small>
        <div className="h-3 w-3 bg-orange-brown ml-1" />
      </div>
      <div className="d-row items-center pr-3">
        <small className="input-label">Confirmed</small>
        <div className="h-3 w-3 bg-blue-500 ml-1" />
      </div>
      <div className="d-row items-center">
        <small className="input-label">Declined</small>
        <div className="h-3 w-3 bg-red-500 ml-1" />
      </div>
    </div>
  );
}
export default ShipmentStatus;
