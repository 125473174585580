import moment from 'moment-timezone';

const toLocalTime = time => {
  return moment(time).local().format('hh:mm A');
};

const getLocalTimezone = () => {
  return moment().tz(moment.tz.guess()).zoneAbbr();
};

const formatTimeWithSeconds = time => {
  return moment(time, 'HH:mm:ss').format('hh:mm A');
};

const formatTime = (time, timeZone = 'America/New_York') => {
  let offset = timeZone ? moment().tz(timeZone)?.utcOffset() : 0;
  let _time = moment(time).local().utcOffset(offset).format('h:mm A');
  return _time === 'Invalid date'
    ? `${time} ${checkAndGetTimezone(timeZone)}`
    : `${_time} ${checkAndGetTimezone(timeZone)}`;
};

const formatDate = (date, format) => {
  return moment(date).local().format(format);
};

const formatDateWithtimzone = (date, format, timeZone = 'America/New_York') => {
  let offset = timeZone ? moment().tz(timeZone)?.utcOffset() : 0;
  return moment(date).local().utcOffset(offset).format(format);
};

const formatDateWithTimzoneInfo = (date, format, timeZone = 'America/New_York') => {
  let offset = timeZone ? moment().tz(timeZone)?.utcOffset() : 0;
  return `${moment(date).local().utcOffset(offset).format(format)} - ${checkAndGetTimezone(
    timeZone
  )}`;
};

const onlyDate = date => {
  return date?.split('T')[0] || '';
};

const isToday = date => {
  return moment().isSame(moment(date).local(), 'day');
};

const subtractTime = (time, minutes, timeZone) => {
  let _time = moment(time, 'HH:mm a').subtract(minutes, 'minutes').format('hh:mm a');

  return _time === 'Invalid date'
    ? `${time || ''} ${checkAndGetTimezone(timeZone)}`
    : `${_time} ${checkAndGetTimezone(timeZone)}`;
};

const to12hourformat = (time, timeZone = 'America/New_York') => {
  return `${moment(time, 'HH:mm a').format('hh:mm a')} ${checkAndGetTimezone(timeZone)}`;
};

const to24hourformat = time => {
  return moment(time, 'hh:mm A').format('HH:mm');
};

const localWithUTCoffset = (time, timezone = 'America/New_York') => {
  let offset = moment().tz(timezone)?.utcOffset();
  return moment(time).local().utcOffset(offset).format('HH:mm');
};

const withtzInfo = (time, timezone = 'America/New_York') => {
  if (time === '00:00') return '00:00';
  let withDate = moment(time, 'HH:mm').toDate();
  return moment.tz(moment(withDate).format('HH:mm'), 'HH:mm', timezone);
};

const utcwithtzInfo = (time, date, timezone = 'America/New_York') => {
  if (time === '00:00') return '00:00';
  let dateTime = moment(date + ' ' + time, 'YYYY-MM-DD HH:mm');
  return moment.tz(moment(dateTime).format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm', timezone).utc();
};

export const checkAndGetTimezone = (timezone = 'America/New_York') => {
  return moment().tz(timezone).utcOffset() === moment().utcOffset()
    ? ''
    : `(${moment().tz(timezone).zoneAbbr()})`;
};

export default {
  formatTime,
  formatDate,
  isToday,
  subtractTime,
  localWithUTCoffset,
  formatTimeWithSeconds,
  toLocalTime,
  withtzInfo,
  checkAndGetTimezone,
  getLocalTimezone,
  formatDateWithtimzone,
  formatDateWithTimzoneInfo,
  to12hourformat,
  utcwithtzInfo,
  to24hourformat,
  onlyDate,
};
