import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import Screen from 'utils/helpers/ScreenSize';

const CustomDatePicker = ({
  setDate,
  selectedDate,
  disabled,
  transparent,
  placeholder,
  minDate,
  maxDate,
  className,
}) => {
  const screenType = Screen.ScreenType();
  const handleDateChangeRaw = e => {
    e.preventDefault();
  };

  return transparent ? (
    <div className="d-row bg-red bg-white justify-between border border-gray-400 rounded-md">
      <div>
        <DatePicker
          selected={selectedDate}
          onChange={date => setDate(date)}
          disabled={disabled}
          dateFormat={'MMM d, yyyy'}
          placeholderText={placeholder}
          minDate={minDate}
          maxDate={maxDate}
          onChangeRaw={handleDateChangeRaw}
          className={`rounded-md pl-3 py-1 z-20 input-label cursor-pointer font-inter-regular text-dark-gray text-left focus:outline-none ${className} ${
            disabled && 'cursor-not-allowed'
          }`}
        />
      </div>

      <div className="bg-gray-300 overflow-hidden rounded-md">
        <FontAwesomeIcon
          icon={faCalendarAlt}
          color={'#707070'}
          size="sm"
          className=" m-1.5 overflow-hidden rounded-lg"
        />
      </div>
    </div>
  ) : screenType.isTabletOrDesktop ? (
    <DatePicker
      selected={selectedDate ? selectedDate : new Date()}
      onChange={date => setDate(date)}
      disabled={disabled}
      minDate={minDate}
      maxDate={maxDate}
      dateFormat={'MMM d, yyyy'}
      onChangeRaw={handleDateChangeRaw}
      className={`rounded-xl px-0 input-label py-2.5 cursor-pointer font-inter-regular border bg-light-purple text-white text-center focus:outline-none ${className} ${
        disabled && 'cursor-not-allowed'
      }`}
    />
  ) : (
    <div className="d-row">
      <DatePicker
        selected={selectedDate ? selectedDate : new Date()}
        onChange={date => setDate(date)}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat={'MMM d, yyyy'}
        onChangeRaw={handleDateChangeRaw}
        className={`rounded-xl px-0 input-label py-2 cursor-pointer font-inter-regular border bg-light-purple text-white text-center focus:outline-none ${className} ${
          disabled && 'cursor-not-allowed'
        }`}
      />
    </div>
  );
};

export default CustomDatePicker;
