import { Fragment, useContext } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { StoreContext } from 'index';
import Screen from 'utils/helpers/ScreenSize';
import LocalStorage from 'utils/helpers/localStorage';
import { useHistory } from 'react-router-dom';
import array from 'utils/helpers/array';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Dropdown() {
  let history = useHistory();
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const screenType = Screen.ScreenType();
  const { currentUser, defaultRegion } = userStore;

  const submitSignOut = e => {
    e.preventDefault();
    store.userStore.setLoader(true);
    setTimeout(() => {
      LocalStorage.removeKey('auth_token');
      store.userStore.clearStoredData();
      history.replace('/login');
      store.userStore.setLoader(false);
    }, 1000);
  };

  const regionEmployees = array.getRegionOrganisations(currentUser);

  const changeRole = (role, label, id) => {
    store.userStore.setDefaultRegion({
      label,
      value: id, //id = employee id
      role,
    });
    history.replace('/');
  };

  return (
    <Menu as="div" className="relative inline-block text-left z-10 mr-0 md:mr-2">
      <div>
        <Menu.Button className="inline-flex justify-center w-full rounded-md font-inter-semibold px-4 py-2 bg-white text-sm font-medium text-black  focus:outline-none">
          <div className="flex flex-col text-left">
            <small className="text-xxs md:text-sm capitalize">
              {currentUser?.data?.attributes?.first_name || ''}{' '}
              {currentUser?.data?.attributes?.last_name || ''}
            </small>
            <small
              className={`input-label text-left text-dark-gray truncate ${
                defaultRegion.role?.includes('hungerhub') ? 'normal-case' : 'capitalize'
              }`}>
              {defaultRegion.role?.replace(/_/g, ' ')}
            </small>
          </div>

          <FontAwesomeIcon
            icon={faChevronDown}
            color={'#000'}
            size="sm"
            className="-mr-1 ml-2 h-5 w-5"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="origin-top-right absolute right-0 mt-0 w-auto rounded-sm shadow-lg bg-white ring-1 ring-black border ring-opacity-5 focus:outline-none">
          <div className="py-0" style={{ minWidth: '150px' }}>
            {array.hasMinimumTwo(regionEmployees) &&
              regionEmployees?.map(({ attributes: { role, organization_name, id } }) => (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => changeRole(role, organization_name, id)}
                      className={classNames(
                        active ? 'text-black bg-gray-100' : 'text-gray-600',
                        'block px-2 py-2 text-sm capitalize w-full text-left'
                      )}>
                      {role?.replace(/_/g, ' ')}
                    </button>
                  )}
                </Menu.Item>
              ))}
            <Menu.Item>
              {({ active }) => (
                <a
                  href="/profile"
                  className={classNames(
                    active ? 'text-black bg-gray-100' : 'text-gray-600',
                    'block px-2 py-2 text-sm'
                  )}>
                  Profile
                </a>
              )}
            </Menu.Item>
            <form onSubmit={e => submitSignOut(e)}>
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="submit"
                    className={classNames(
                      active ? 'text-black bg-gray-100' : 'text-gray-600',
                      'block w-full text-left px-2 py-2 text-sm'
                    )}>
                    Logout
                  </button>
                )}
              </Menu.Item>
            </form>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default observer(Dropdown);
