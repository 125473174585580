export const capitalizeFirstLetter = string => {
  return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
};

export const removeUnderscore = string => {
  return string?.replace(/_/g, ' ');
};

export const valueIfNumber = value => {
  return value && !isNaN(+value) ? Number(value) : '';
};

export const shortHandText = str => {
  return str?.length > 20 ? str?.substring(0, 25) + '...' : str;
};

export const changeSpacesToHyphens = string => {
  return string && string.replace(/\s/g, '-');
};

export const removeChar = (str, character = '') => {
  return str && str.replace(character, '');
};

export const splitStr = (str, character = '') => {
  return str && str.split(character)[0];
};

export const lower = str => {
  return str && str.toLowerCase();
};
