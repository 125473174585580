import { useState } from 'react';
import { Switch } from '@headlessui/react';

function RadioSelector({ setSelector, selectorValue, firstLabel, secondLabel, thirdLabel }) {
  return (
    <div className="d-col mt-4">
      <div className="flex flex-col md:flex-row">
        <div className="d-row mr-4">
          <label className="inline-flex items-center">
            <input
              type="radio"
              className="form-radio"
              value="1"
              checked={selectorValue === '1'}
              onClick={e => setSelector(e.target.value)}
            />
            <span className="ml-2 text-xs font-inter-regular">{firstLabel}</span>
          </label>
        </div>

        <div className="d-row my-2 md:my-0">
          <label className="inline-flex items-center">
            <input
              type="radio"
              className="form-radio"
              value="2"
              checked={selectorValue === '2'}
              onClick={e => setSelector(e.target.value)}
            />
            <span className="mx-2 text-xs font-inter-regular">{secondLabel}</span>
          </label>
        </div>

        {thirdLabel && (
          <div className="d-row">
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio"
                value="3"
                checked={selectorValue === '3'}
                onClick={e => setSelector(e.target.value)}
              />
              <span className="ml-2 text-xs font-inter-regular">{thirdLabel}</span>
            </label>
          </div>
        )}
      </div>
    </div>
  );
}
export default RadioSelector;
