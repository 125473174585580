import { removeUnderscore } from 'utils/helpers/string';

const MenuElementStatus = ({ status, light = false, mediumSized = false }) => {
  return (
    <strong
      className={`input-label w-1/3 self-end text-right capitalize font-inter-medium ${
        light ? 'text-white' : 'text-dark-purple'
      } ${mediumSized ? 'text-sm' : 'text-xs'} ${
        status === 'sold_out' || status === 'in_active' ? 'visible' : 'invisible'
      }`}>
      {removeUnderscore(status)}
    </strong>
  );
};

export default MenuElementStatus;
