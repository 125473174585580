const ErrorToast = ({ error, visible, transparent }) => {
  return (
    <div>
      {visible ? (
        <div
          className={`${
            !transparent && 'bg-red-100 border border-red-400 px-4 mt-2'
          } text-red-700 py-3 rounded relative animate-fade-in-down`}
          role="alert">
          <span className="block sm:inline text-sm">{error}</span>
        </div>
      ) : null}
    </div>
  );
};

export default ErrorToast;
