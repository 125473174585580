import React from 'react';
import {
  faFileAlt,
  faCalendarAlt,
  faPaperPlane,
  faCalculator,
  faUsers,
  faBell,
  faTags,
  faConciergeBell,
  faUtensils,
} from '@fortawesome/free-solid-svg-icons';

export default {
  operations: {
    routes: [
      {
        label: 'Delivery Management',
        icon: faCalendarAlt,
        url: '/delivery-management',
        childRoutes: [
          '/delivery-management',
          '/delivery-management',
          '/delivery-detail-zone',
          '/delivery-detail-location',
          '/delivery-detail-restaurant',
        ],
      },
      {
        label: 'Restaurant Management',
        icon: faConciergeBell,
        url: '/restaurant-management',
        childRoutes: [
          '/restaurant-management',
          '/restaurant-search',
          '/restaurant-detail',
          '/restaurant-menu-editor',
        ],
      },
      {
        label: 'User Management',
        icon: faCalculator,
        url: '/user-management',
        childRoutes: ['/user-management', '/user-search', '/user-detail'],
      },
      {
        label: 'Account Management',
        icon: faUtensils,
        url: '/account-management',
        childRoutes: [
          '/account-management',
          '/account-search',
          '/account-detail',
          '/meal-management-summary',
        ],
      },
      {
        icon: faUtensils,
        label: 'Meal Management',
        url: '/admin-meal-management',
        childRoutes: ['/admin-meal-management'],
      },
    ],
  },
  hungerhub_super_admin: {
    routes: [
      {
        label: 'Staff Management',
        icon: faFileAlt,
        url: '/staff-management',
        childRoutes: ['/staff-management'],
      },
      {
        label: 'User Management',
        icon: faUsers,
        url: '/user-management',
        childRoutes: ['/user-management', '/user-search', '/user-detail'],
      },
      {
        label: 'Restaurant Management',
        icon: faConciergeBell,
        url: '/restaurant-management',
        childRoutes: [
          '/restaurant-management',
          '/restaurant-search',
          '/restaurant-detail',
          '/restaurant-menu-editor',
        ],
      },
      {
        label: 'Account Management',
        icon: faCalendarAlt,
        url: '/account-management',
        childRoutes: [
          '/account-management',
          '/account-search',
          '/account-detail',
          '/meal-management-summary',
        ],
      },
      {
        icon: faPaperPlane,
        label: 'Place Order',
        url: '/place-order',
        childRoutes: ['/place-order'],
      },
      {
        label: 'Restaurant Schedules',
        icon: faCalendarAlt,
        url: '/restaurant-schedules',
        childRoutes: ['/restaurant-schedules'],
      },
      {
        icon: faTags,
        label: 'Restaurant Tag Management',
        url: '/restaurant-tag-management',
        disabled: true,
        childRoutes: ['/restaurant-tag-management'],
      },
      {
        icon: faBell,
        label: 'Market Management',
        url: '/market-management',
        childRoutes: ['/market-management'],
      },
      {
        icon: faCalculator,
        label: 'Accounting Management: Client',
        url: '/accounting-management-client',
        disabled: true,
        childRoutes: ['/accounting-management-client'],
      },
      {
        icon: faCalculator,
        label: 'Accounting: Restaurant',
        url: '/accounting-management-restaurant',
        disabled: true,
        childRoutes: ['/accounting-management-restaurant'],
      },

      {
        icon: faUtensils,
        label: 'Meal Management',
        url: '/admin-meal-management',
        childRoutes: ['/admin-meal-management'],
      },
      {
        icon: faBell,
        label: 'Delivery Management',
        url: '/delivery-management',
        childRoutes: [
          '/delivery-management',
          '/delivery-management',
          '/delivery-detail-zone',
          '/delivery-detail-location',
          '/delivery-detail-restaurant',
        ],
      },
    ],
  },
  hungerhub_admin: {
    routes: [
      {
        label: 'Staff Management',
        icon: faFileAlt,
        url: '/staff-management',
        childRoutes: ['/staff-management'],
      },
      {
        label: 'Restaurant Schedules',
        icon: faCalendarAlt,
        url: '/restaurant-schedules',
        childRoutes: ['/restaurant-schedules'],
      },
      {
        icon: faBell,
        label: 'Market Management',
        url: '/market-management',
        childRoutes: ['/market-management'],
      },
    ],
  },
  account_management: {
    routes: [
      {
        label: 'Account Management',
        icon: faCalendarAlt,
        url: '/account-management',
        childRoutes: [
          '/account-management',
          '/account-search',
          '/account-detail',
          '/meal-management-summary',
        ],
      },
      {
        icon: faCalculator,
        disabled: true,
        label: 'Accounting Management: Client',
        url: '/accounting-management-client',
        childRoutes: ['/accounting-management-client'],
      },
      {
        icon: faUtensils,
        label: 'Meal Management',
        url: '/admin-meal-management',
        childRoutes: ['/admin-meal-management'],
      },
      {
        label: 'Restaurant Management',
        icon: faConciergeBell,
        url: '/restaurant-management',
        childRoutes: [
          '/restaurant-management',
          '/restaurant-search',
          '/restaurant-detail',
          '/restaurant-menu-editor',
        ],
      },
      {
        label: 'User Management',
        icon: faUsers,
        url: '/user-management',
        childRoutes: ['/user-management', '/user-search', '/user-detail'],
      },
      {
        icon: faPaperPlane,
        label: 'Place Order',
        url: '/place-order',
        childRoutes: ['/place-order'],
      },
    ],
  },
  restaurant_management: {
    routes: [
      {
        label: 'Restaurant Management',
        icon: faConciergeBell,
        url: '/restaurant-management',
        childRoutes: [
          '/restaurant-management',
          '/restaurant-search',
          '/restaurant-detail',
          '/restaurant-menu-editor',
        ],
      },
      {
        icon: faTags,
        label: 'Restaurant Tag Management',
        disabled: true,
        url: '/restaurant-tag-management',
        childRoutes: ['/restaurant-tag-management'],
      },
      {
        label: 'Restaurant Schedules',
        icon: faCalendarAlt,
        url: '/restaurant-schedules',
        childRoutes: ['/restaurant-schedules'],
      },
      {
        icon: faCalculator,
        label: 'Accounting: Restaurant',
        disabled: true,
        url: '/accounting-management-restaurant',
        childRoutes: ['/accounting-management-restaurant'],
      },
      {
        icon: faUtensils,
        label: 'Meal Management',
        url: '/admin-meal-management',
        childRoutes: ['/admin-meal-management'],
      },
    ],
  },
  customer_service: {
    routes: [
      {
        label: 'User Management',
        icon: faUsers,
        url: '/user-management',
        childRoutes: ['/user-management', '/user-search', '/user-detail'],
      },
      {
        label: 'Restaurant Management',
        icon: faConciergeBell,
        url: '/restaurant-management',
        childRoutes: [
          '/restaurant-management',
          '/restaurant-search',
          '/restaurant-detail',
          '/restaurant-menu-editor',
        ],
      },
      {
        label: 'Account Management',
        icon: faCalendarAlt,
        url: '/account-management',
        childRoutes: [
          '/account-management',
          '/account-search',
          '/account-detail',
          '/meal-management-summary',
        ],
      },
      {
        icon: faPaperPlane,
        label: 'Place Order',
        url: '/place-order',
        childRoutes: ['/place-order'],
      },
      {
        icon: faUtensils,
        label: 'Meal Management',
        url: '/admin-meal-management',
        childRoutes: ['/admin-meal-management'],
      },
    ],
  },
  restaurant_support: {
    routes: [
      {
        label: 'Restaurant Management',
        icon: faConciergeBell,
        url: '/restaurant-management',
        childRoutes: [
          '/restaurant-management',
          '/restaurant-search',
          '/restaurant-detail',
          '/restaurant-menu-editor',
        ],
      },
    ],
  },
  delivery_support: {
    routes: [
      {
        icon: faBell,
        label: 'Delivery Management',
        url: '/delivery-management',
        childRoutes: [
          '/delivery-management',
          '/delivery-management',
          '/delivery-detail-zone',
          '/delivery-detail-location',
          '/delivery-detail-restaurant',
        ],
      },
    ],
  },
  account_management_support: {
    routes: [
      {
        label: 'Account Management',
        icon: faCalendarAlt,
        url: '/account-management',
        childRoutes: [
          '/account-management',
          '/account-search',
          '/account-detail',
          '/meal-management-summary',
        ],
      },
    ],
  },
  meal_management_support: {
    routes: [
      {
        icon: faUtensils,
        label: 'Meal Management',
        url: '/admin-meal-management',
        childRoutes: ['/admin-meal-management'],
      },
    ],
  },

  delivery_overview: {
    routes: [
      {
        icon: faBell,
        label: 'Delivery Management',
        url: '/delivery-management',
        childRoutes: [
          '/delivery-management',
          '/delivery-management',
          '/delivery-detail-zone',
          '/delivery-detail-location',
          '/delivery-detail-restaurant',
        ],
      },
    ],
  },

  menu_editor: {
    routes: [
      {
        label: 'Restaurant Management',
        icon: faConciergeBell,
        url: '/restaurant-management',
        childRoutes: [
          '/restaurant-management',
          '/restaurant-search',
          '/restaurant-detail',
          '/restaurant-menu-editor',
        ],
      },
    ],
  },

  common: {
    routes: [],
  },
};
