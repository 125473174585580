import React, { useContext } from 'react';
import { toJS } from 'mobx';
import { StoreContext } from 'index';
import { useObserver, useLocalStore } from 'mobx-react'; // 6.x or mobx-react-lite@1.4.0

function useEmployeeId(props) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);

  return useObserver(() => ({
    employeeId: userStore?.defaultRegion?.value,
    defaultRegion: userStore?.defaultRegion?.label,
  }));
}

export default useEmployeeId;
