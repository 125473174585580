const removeExtraKeys = obj => {
  return Object?.entries(obj).reduce(
    (a, [k, v]) => (v !== null && v !== undefined && v !== '' ? ((a[k] = v), a) : a),
    {}
  );
};

const isEmpty = obj => {
  return obj && Object?.keys(obj).length === 0;
};

const isPresent = obj => {
  if (obj) return obj && Object?.keys(obj).length > 0;
  else return false;
};

const getParams = queryParams => {
  return Object?.fromEntries(new URLSearchParams(queryParams));
};

export default {
  removeExtraKeys,
  isEmpty,
  getParams,
  isPresent,
};
