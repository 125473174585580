import React, { useState, useContext, useMemo, useReducer, useEffect } from 'react';
import Screen from 'utils/helpers/ScreenSize';
import { StoreContext } from 'index';
import { observer } from 'mobx-react';
import Validation from 'utils/helpers/validation';
import { toJS } from 'mobx';
import { DataLoading, MenuElementStatus, NotFound } from 'components/Shared';
import useEmployeeId from 'utils/helpers/user';

function MenuOptions({ currentOptions, item, optionsLoader, addItemToCart, ...props }) {
  const store = useContext(StoreContext);
  const [selected, setSelected] = useState(null);
  const [getItemTotalPrice, setItemTotalPrice] = useState(0);
  const [optionTypesPrice, setOptionTypesPrice] = useState(0);

  const screenType = Screen.ScreenType();
  const userStore = toJS(store.userStore);
  const userManagementStore = toJS(store.userManagementStore);
  const { employeeId } = new useEmployeeId();
  let { optionCategories } = userManagementStore;

  const initialState = {
    isInTime: true,
    currentPrice: 0,
    basePrice: '',
    optionTypes: [],
    freeAddons: [],
    paidAddons: [],
    instructions: '',
    addItem: {
      options: [],
      addons: {},
      instructions: '',
    },
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }
  const [provider, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({
      field: 'currentPrice',
      value: parseFloat(getItemTotalPrice).toFixed(2),
    });

    initRequiredCheck();
  }, [currentOptions]);

  const getOptionTypeIDs = () => {
    dispatch({
      field: 'addItem',
      value: { ...provider.addItem, options: Object.values(provider.optionTypes).flat() },
    });
  };

  useMemo(() => {
    let selectedOptionsSum = 0;

    selectedOptionsSum = Number(item.price);

    setItemTotalPrice(
      parseFloat(
        (parseFloat(optionTypesPrice) + (selectedOptionsSum || parseFloat(item.price))).toFixed(2)
      )
    );
  }, [item, optionTypesPrice]);

  useMemo(() => {
    const options = currentOptions;
    getOptionTypeIDs();
    const selectedOptionsValues = Object.values(provider.optionTypes).flat();

    let price = 0;
    options?.forEach((e, index) => {
      e.attributes.options.forEach(option => {
        if (selectedOptionsValues.includes(option.id)) {
          price += parseFloat(option.attributes.price);
        }
      });
    });

    setOptionTypesPrice(price);
  }, [item, provider?.optionTypes]);

  const initRequiredCheck = () => {
    let OT = []; //option types
    let options = currentOptions;
    // Create optionTypes object so that options can separated into their own groups
    // to account for different limits of options per group

    options?.forEach(option => {
      OT = Object.assign({}, OT, {
        [option.id]: [],
      });
    });

    // The following is to check a radio button if the option is required
    options?.forEach(optionType => {
      // Set required for radio buttons
      if (
        optionType.attributes.required &&
        optionType.attributes.options.length &&
        optionType.attributes.choose_upto === 1
      ) {
        let activeOptions = optionType.attributes.options?.filter(
          option => option?.attributes?.availability === 'active'
        );
        if (activeOptions?.length > 0) OT[optionType.id] = activeOptions[0].id;
      }
      // Set required for limited checkbox menu items
      if (
        optionType.attributes.required &&
        optionType.attributes.options.length &&
        (optionType.attributes.choose_upto >= 2 || optionType.attributes.choose_upto === 0)
      ) {
        let activeOptions = optionType.attributes.options?.filter(
          option => option?.attributes?.availability === 'active'
        );
        if (activeOptions?.length > 0) OT[optionType.id] = [activeOptions[0].id];
      }
    });

    dispatch({
      field: 'optionTypes',
      value: OT,
    });
  };

  const radioCheck = (id, optionTypeId, type, required) => {
    let options = Object.values(provider.optionTypes).flat();

    if (Object.values(options).includes(id) && !required) {
      dispatch({
        field: 'optionTypes',
        value: Object.assign({}, provider.optionTypes, {
          [optionTypeId]: [],
        }),
      });
    } else {
      dispatch({
        field: 'optionTypes',
        value: Object.assign({}, provider.optionTypes, {
          [optionTypeId]: id,
        }),
      });
    }
  };

  const onCheckboxChange = (id, optionType, optionTypesArr, event) => {
    //prevent checkbox to uncheck if its required
    if (optionType.attributes.required && optionTypesArr.length === 1 && !event.target.checked) {
      event.preventDefault();
      return;
    }

    dispatch({
      field: 'optionTypes',
      value: Object.assign({}, provider.optionTypes, {
        [optionType.id]: event.target.checked
          ? optionTypesArr?.concat(id)
          : optionTypesArr?.filter(option => option !== id),
      }),
    });
  };

  const addItem = () => {
    let options = Object.values(provider.optionTypes).flat();
    addItemToCart(item.id, options, provider.instructions);

    dispatch({ type: 'reset' });
    initRequiredCheck();
  };

  return (
    <div>
      {currentOptions?.map(optionType => (
        <div className="d-col px-2" key={optionType.id}>
          {optionType.attributes.choose_upto === 1 && (
            <div class="py-1 w-full">
              <small className="font-inter-semibold text-sm text-black">
                {optionType?.attributes.name}
                {optionType.attributes.required ? '*' : null}
              </small>

              <div className="border border-black-100 my-2" />

              {optionType?.attributes?.options?.map(option => (
                <div key={option.id} className="d-col p-1.5">
                  <div className="d-row justify-between px-0 md:px-4 text-right">
                    <label class="d-flex align-items-center font-inter-regular text-xs break-normal">
                      <input
                        type="radio"
                        value={option.id}
                        class="radio option_select_id mr-2"
                        checked={provider.optionTypes[optionType.id] == option.id}
                        disabled={option.attributes?.availability !== 'active'}
                        onChange={e =>
                          radioCheck(
                            option.id,
                            optionType.id,
                            optionType.attributes.name,
                            optionType.attributes.required
                          )
                        }
                      />
                      {option.attributes.name}
                    </label>

                    <small className="font-inter-medium text-xs">
                      {option?.attributes?.availability === 'sold_out'
                        ? 'Sold Out'
                        : `+ ${Validation.priceFormatter(option.attributes.price)}`}
                    </small>
                  </div>
                </div>
              ))}
            </div>
          )}

          {optionType.attributes.choose_upto === 0 && (
            <div class="py-1 w-full">
              <small className="font-inter-semibold capitalize text-sm text-black">
                {optionType.attributes.name} (unlimited)
                {optionType.attributes.required ? '*' : null}
              </small>

              <div className="border border-black-100 my-2" />

              {optionType?.attributes?.options?.map(option => (
                <div key={option.id} className="d-col p-1.5">
                  <div className="d-row justify-between px-0 md:px-4 text-right">
                    <label class="d-flex align-items-center font-inter-regular text-xs break-normal">
                      <input
                        type="checkbox"
                        disabled={option.attributes?.availability !== 'active'}
                        value={option.id}
                        checked={provider.optionTypes[optionType.id]?.includes(option.id)}
                        onChange={e =>
                          onCheckboxChange(
                            option.id,
                            optionType,
                            provider?.optionTypes[optionType.id],
                            e
                          )
                        }
                        class=" mr-2"
                      />
                      {option.attributes.name}
                    </label>

                    <small className="font-inter-medium text-xs">
                      {option?.attributes?.availability === 'sold_out'
                        ? 'Sold Out'
                        : `+ ${Validation.priceFormatter(option.attributes.price)}`}
                    </small>
                  </div>
                </div>
              ))}
            </div>
          )}

          {optionType.attributes.choose_upto >= 2 && (
            <div class="py-1 w-full">
              <small className="font-inter-semibold capitalize text-sm text-black">
                {optionType.attributes.name} {`choose up to ${optionType.attributes.choose_upto}`}
                {optionType.attributes.required ? '*' : null}
              </small>

              <div className="border border-black-100 my-2" />

              {optionType?.attributes?.options?.map(option => (
                <div key={option.id} className="d-col p-1.5">
                  <div className="d-row justify-between px-0 md:px-4 text-right">
                    <label class="d-flex align-items-center font-inter-regular text-xs break-normal">
                      <input
                        type="checkbox"
                        value={option.id}
                        checked={provider?.optionTypes[optionType.id]?.includes(option.id)}
                        disabled={
                          option.attributes?.availability !== 'active' ||
                          (provider?.optionTypes[optionType.id]?.length >=
                            optionType.attributes.choose_upto &&
                            provider?.optionTypes[optionType.id]?.indexOf(option.id) === -1)
                        }
                        onChange={e =>
                          onCheckboxChange(
                            option.id,
                            optionType,
                            provider?.optionTypes[optionType.id],
                            e
                          )
                        }
                        class=" mr-2"
                      />
                      {option.attributes.name}
                    </label>

                    <small className="font-inter-medium text-xs">
                      {option?.attributes?.availability === 'sold_out'
                        ? 'Sold Out'
                        : `+ ${Validation.priceFormatter(option.attributes.price)}`}
                    </small>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}

      {optionsLoader && <DataLoading />}

      <div className="d-col mx-2 my-3">
        <small className="text-dark-gray">Special Instructions</small>
        <textarea
          onChange={e => dispatch({ field: 'instructions', value: e.target.value })}
          className="w-100 mt-2 p-2 border border-gray-300 rounded-md text-xs"
          rows="3"
          value={provider.instructions}
          placeholder="Special instructions are fulfilled at restaurant's discretion and not guaranteed"
        />
      </div>

      <div className="d-col mx-2 mt-3">
        <button onClick={() => addItem()} type="button" className="btn-purple text-sm">
          Add to Cart ${getItemTotalPrice.toFixed(2)}
        </button>
      </div>
    </div>
  );
}

export default observer(MenuOptions);
