import * as React from 'react';
import Cell from './Cell';
import { Link } from 'react-router-dom';

export default class DataTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cellHeights: [],
    };

    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.handleCellHeightResize();
  }

  renderHeadingRow = (_cell, cellIndex) => {
    const { headings, onSortClick, currentSort } = this.props;
    const { cellHeights } = this.state;

    return (
      <Cell
        key={`heading-${cellIndex}`}
        content={headings[cellIndex]}
        header={true}
        onSortClick={onSortClick}
        fixed={cellIndex === 0}
        currentSort={currentSort}
        height={cellHeights[0]}
      />
    );
  };

  renderRow = (_row, rowIndex) => {
    const { rows, shiftLeft, fixedTable } = this.props;
    const { cellHeights } = this.state;
    const heightIndex = rowIndex + 1;

    return (
      <tr className="odd:bg-table-row hover:bg-gray-50 cursor-pointer" key={`row-${rowIndex}`}>
        {rows[rowIndex].map((_cell, cellIndex) => {
          return (
            <Cell
              key={`${rowIndex}-${cellIndex}`}
              content={rows[rowIndex][cellIndex]}
              linkToPath={this.props.linkToPath}
              fixed={cellIndex === 0}
              height={cellHeights[heightIndex]}
              shiftLeft={shiftLeft}
              fixedTable={fixedTable}
            />
          );
        })}
      </tr>
    );
  };

  setTable = table => {
    this.table = table;
  };

  getTallestCellHeights = () => {
    const rows = Array.from(this.tableRef.current.getElementsByTagName('tr'));
    let { cellHeights } = this.state;

    cellHeights = rows.map(row => {
      const fixedCell = row.childNodes[0];
      return Math.max(row.clientHeight, fixedCell.clientHeight);
    });

    return cellHeights;
  };

  handleCellHeightResize = () => {
    this.setState({ cellHeights: this.getTallestCellHeights() });
  };

  render() {
    const { headings, rows } = this.props;

    this.renderHeadingRow = this.renderHeadingRow.bind(this);
    this.renderRow = this.renderRow.bind(this);

    const theadMarkup = <tr key="heading">{headings.map(this.renderHeadingRow)}</tr>;

    const tbodyMarkup = rows.map(this.renderRow);

    return (
      <div className={this.props.className}>
        <div className="ScrollContainer shadow-sm overflow-visible rounded-md border-gray-200 border sm:rounded-lg">
          <table className="w-full" ref={this.tableRef}>
            <thead className="bg-white border-b">{theadMarkup}</thead>
            <tbody>{tbodyMarkup}</tbody>
          </table>
        </div>
      </div>
    );
  }
}
