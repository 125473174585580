import React from 'react';
import order from 'utils/helpers/order';

function ETA({ eta }) {
  return (
    <div
      className="bg-yellow-200 rounded-full d-row text-center justify-center items-center overflow-hidden"
      style={{ width: '100px', height: '30px' }}>
      <small
        className=" text-gray-700 truncate px-1 font-inter-medium"
        style={{ fontSize: '11px' }}>
        {order.extractETA(eta)}
      </small>
    </div>
  );
}

export default ETA;
