import * as React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import dateTime from 'utils/helpers/dateTime';

export default function _TimePicker({ onChange, value, disabled = false, timeZone = null }) {
  return (
    <div className="d-col">
      {timeZone && <small className="input-label">{dateTime.checkAndGetTimezone(timeZone)}</small>}
      <Stack component="form" noValidate spacing={3}>
        <TextField
          id="time"
          label=""
          type="time"
          className={`py-0 ${disabled && 'bg-red-100 opacity-50'}`}
          defaultValue="07:30"
          disabled={disabled}
          onKeyDown={e => {
            if (e.nativeEvent.key === 'Backspace') {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          required
          size="small"
          onChange={e => onChange(e.target.value)}
          value={value}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
          sx={{ width: 150 }}
          variant="outlined"
        />
      </Stack>
    </div>
  );
}
